<div class="footer">
  <div class="footer-top">
    <div class="container">
      <div class="footer-top__block">
        <div class="footer-logo">
          <mg-logo [height]="'3.2rem'"></mg-logo>
          <div class="footer-logo__additional">
            <app-authorized-button></app-authorized-button>
            <a href="https://sk.ru/" target="_blank">
              <img alt="Sk" src="assets/images/sk-logo-small.svg" />
            </a>
          </div>
        </div>
        <div class="footer-top__contacts">
          <div class="d-lg-flex footer-top__contacts_line">
            <div class="d-md-flex align-items-center">
              <a
                class="footer-top__contacts-link social-link social-tg"
                href="https://t.me/MarketGuru_Support_bot"
                target="_blank"
              >
                {{ '@MarketGuru_Support_bot' }}
              </a>
              <a class="footer-top__contacts-link social-link social-tel" href="tel:+79882561000" target="_blank">
                <i class="mg-icon-call"></i>
                +7 (988) 256-1000
              </a>
              <a
                class="footer-top__contacts-link social-link social-mail"
                href="mailto:support@marketguru.io"
                target="_blank"
              >
                {{ 'support@marketguru.io' }}
              </a>
            </div>
            <div class="m-auto"></div>
            <div class="d-flex align-items-center footer-top__socials">
              <div class="footer-top__socials-title">Присоединяйтесь к&nbsp;нам</div>
              <div class="d-flex align-items-center footer-top__socials-buttons">
                <a
                  class="footer-top__contacts-button social-button social-tg"
                  href="https://t.me/marketguruclub"
                  target="_blank"
                ></a>
                <a
                  class="footer-top__contacts-button social-button social-yt"
                  href="https://youtube.com/channel/UCJ5GIk9u1PE6IPDTwXmCimw"
                  target="_blank"
                ></a>
              </div>
            </div>
          </div>
          <div class="footer-top__contacts_blocks">
            <div class="footer-top__contacts_block">
              <div class="footer-top__contacts_block_header">Юридические данные</div>
              <div>
                ООО «МАРКЕТГУРУ»
                <br />Общество с ограниченной ответственностью «МаркетГуру» <br /><span>ИНН:</span> 61541&zwj;60950
                <br /><span>ОГРН:</span> 1216100023897
              </div>
            </div>
            <div class="footer-top__contacts_block">
              <div class="footer-top__contacts_block_header">Адрес</div>
              <div>
                <span>Юридический</span>
                <br />347904, Россия, Ростовская область, г. Таганрог,<br />пл. Восстания, д. 3, к. 2, этаж/литер 2/А
                пом.3
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-copy">
    <div class="container">
      <div class="footer-copy__row">
        <div>&copy; {{ currentYear }} MarketGuru inc.</div>
        <div class="footer-copy__links">
          <a routerLink="/privacy">Публичная оферта</a>
          <a routerLink="/confidentiality">Политика конфиденциальности</a>
        </div>
      </div>
    </div>
  </div>
</div>
